// above are  polyfill
import React from 'react';
import { Home } from "src/assembled"
import { SEO } from "src/components/utils"
import { Footer } from "src/components"


export default function Root({ location }) {
  // seo
  const seo = <SEO 
  pathname={location.pathname} />


  // render
  const render = <main role="main">
    <Home location={location} />
  </main>
  

  return <>
    {seo}
    {render}
    <Footer location={location} />
  </>
}
